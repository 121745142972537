body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #202020;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}


.contenedor-ruleta {
  transform: rotate(180deg);
}

.contenedor-ruleta::before {
  content: "";
  width: 60px;
  height: 60px;
  background-color: white;
  position: absolute;
  z-index: 99999;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  pointer-events: none;
}

.ruleta {
  /*background-color: #303030;*/
  border-radius: 360px;
  position: relative;
  overflow: hidden;

  -webkit-animation-timing-function: cubic-bezier(0, 0.4, 0.4, 1.04);
  animation-timing-function: cubic-bezier(0, 0.4, 0.4, 1.04);
  -webkit-animation-duration: 5.8s;
  animation-duration: 5.8s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.ruleta::before {
  content: "";
  width: 100px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  z-index: 9999;
  border-radius: 360px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.opcion {
  border: 0 solid transparent;
  position: absolute;
  transform-origin: top center;
  top: 50%;
}

.opcion::before {
  z-index: 99999;
  position: absolute;
  display: block;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  font-family: sans-serif;
  width: 40px;
  line-height: 40px;
  left: -20px;
  margin-top: 125px;
  transform: rotate(180deg);
}
